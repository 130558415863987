<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Package </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPackage">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="package_name"
                label="Name"
                placeholder="Input Name"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="amount_usdt"
                label="Amounts (USDT)"
                type="number"
                placeholder="Input Amounts"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="profits"
                label="Share Profits(%)"
                type="number"
                placeholder="Input Share Profits(%)"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="periods"
                label="Lock-Up Periods"
                type="number"
                placeholder="Input Lock-Up Periods"
              />
              </CCol>      
            </CRow>  
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/packages">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Package Gagal Input.
    </CModal>
   
  </div>   
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      // kode : "",
      // nama : "",
      package_name : "",
      amount_usdt : "",
      profits : "",
      periods : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  methods: {
    inputPackage: function(event){
      const pkg = { 
                          // kode:this.kode,
                          package_name: this.package_name, 
                          amount_usdt: this.amount_usdt, 
                          profits: this.profits, 
                          periods: this.periods, 
                          };
      axios.post(process.env.VUE_APP_BASE_URL+"addpackage", pkg)
        .then((response) => {
            if(response.data=='sukses'){
              this.$router.push('/package');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>